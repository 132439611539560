import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Builders = () => {
    return (
        <Layout>
             <Seo
      title="Housing Developments - BIKESAFE"
      description="Bike Safe's compact and great value products means that cycle parking no longer needs to be such a headache for housing developers. Speak to one of our representatives who can recommend the most suitable solution for you."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Housing Developments
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <p className="flow-text">
                        Bike Safe's compact and great value products means that cycle parking no longer needs to be such a headache for housing developers. Speak to one of our representatives who can recommend the most suitable solution for you.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="text-center">
                        <StaticImage placeholder="blurred"  src="../images/bikestand.jpg" className="shadow mt-5 mb-5 fluid rounded" />
                        <p>
                            <em className="flow-text">"We were completely satisfied with your service. Which is quite refreshing in these troubled times!"
                                <strong> James Whiteley (Buyer) Sir Robert Mcalpine.</strong>.</em></p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Builders;